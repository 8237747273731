<div *tuiLet="appStateService.select('sidebarVisible') | async as sidebarOpen" class="flex">
    <aside
        class="fixed left-0 top-0 h-screen border-r border-slate-300 bg-base-200"
        [ngClass]="{
            'w-48': sidebarOpen,
            'w-16': !sidebarOpen,
        }"
    >
        <app-advertiser-sidebar
            [isDark]="themeService.isDark()"
            [isSmall]="!sidebarOpen"
        ></app-advertiser-sidebar>
    </aside>

    <div
        class="flex-1"
        [ngClass]="{
            'ml-48': sidebarOpen,
            'ml-16': !sidebarOpen,
        }"
    >
        <nav class="sticky top-0 z-30">
            <app-user-navbar></app-user-navbar>

            <div class="bg-base-200 px-4">
                <tui-breadcrumbs size="l" class="text-slate-500">
                    @for (item of items; track item) {
                        <a
                            class="inline-flex items-center gap-2"
                            *tuiItem
                            tuiLink
                            [routerLink]="item.routerLink"
                        >
                            @if (item.icon) {
                                <i class="text-sm" [ngClass]="item.icon"></i>
                            }
                            {{ item.label }}
                        </a>
                    }
                </tui-breadcrumbs>
            </div>
        </nav>

        <div class="flex">
            <main class="block w-full bg-base-200 p-4 pb-20">
                <ng-content></ng-content>
            </main>

            <!-- ad side -->
            @if (!proPlan) {
                <div class="w-[160px]">
                    <div
                        class="flex h-[600px] w-[160px] items-center justify-center bg-base-300"
                        style="position: fixed; bottom: 98px"
                    >
                        (160 x 600)
                    </div>
                </div>
            }
        </div>

        <!-- ad bottom and mobile -->
        @if (!proPlan) {
            <div class="fixed bottom-0 right-0 z-0">
                <div
                    class="flex h-[50px] w-[320px] items-center justify-center bg-base-300 sm:h-[98px] sm:w-[720px]"
                >
                    (720x98)
                </div>
            </div>
        }
    </div>
</div>
