import { Component, inject } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { TuiDialogContext } from '@taiga-ui/core'
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus'

@Component({
    selector: 'app-profile-upload-warning',
    standalone: true,
    imports: [RouterModule],
    templateUrl: './profile-upload-warning.component.html',
    styleUrl: './profile-upload-warning.component.scss',
})
export class ProfileUploadWarningComponent {
    private readonly context = inject<TuiDialogContext>(POLYMORPHEUS_CONTEXT)
    private readonly router = inject(Router)

    closeAndNavigate(): void {
        this.context.completeWith(null)
        this.router.navigate(['/dashboard/profile'])
    }
}
