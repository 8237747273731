import { AsyncPipe, CommonModule } from '@angular/common'
import { Component, OnInit, inject } from '@angular/core'
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router'
import { ThemeService } from '@core/services/theme.service'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { FeedbackApiService } from '@modules/feedback/services/feedback-api.service'
import { UserNavbarComponent } from '@modules/navbar/components/user-navbar/user-navbar.component'
import { UserSidebarComponent } from '@modules/sidebar/components/user-sidebar/user-sidebar.component'
import { DashboardRoutes, dashboardRoutes } from '@pages/dashboard/dashboard.routes'
import { map } from 'rxjs'

@Component({
    selector: 'app-layout-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        UserSidebarComponent,
        UserNavbarComponent,
        AsyncPipe,
        ...TaigaUiImports,
    ],
    templateUrl: './layout-sidebar.component.html',
    styleUrls: ['./layout-sidebar.component.scss'],
})
export class LayoutSidebarComponent implements OnInit {
    authStateService = inject(AuthStateService)
    themeService = inject(ThemeService)
    private router = inject(Router)
    appStateService = inject(AppStateService)
    feedbackService = inject(FeedbackApiService)

    readonly dashboardRoutes: DashboardRoutes = dashboardRoutes

    items: { label: string; routerLink: string; icon?: string }[] = []
    currentRoute = ''
    showAds = false // for now, not showing any ads. Will be removed later
    proPlan$ = this.appStateService
        .select('organization')
        .pipe(
            map(
                (organization) =>
                    organization?.isTrialing === false && organization.purchaseDate !== null,
            ),
        )

    ngOnInit(): void {
        this.initBreadcrumbMenuItems(this.router.url)
        this.updateBreadcrumbMenuItems()
    }

    initBreadcrumbMenuItems(url: string) {
        this.items = [
            {
                label: 'Dashboard',
                routerLink: this.dashboardRoutes.index.path,
                icon: 'bx bx-home',
            },
        ]

        if (url.includes('article-editor')) {
            this.items.push({
                label: 'AI Writer',
                routerLink: this.dashboardRoutes.articleEditor.path,
            })
        }

        if (url.includes('research')) {
            this.items.push({
                label: 'Research',
                routerLink: this.dashboardRoutes.research.path,
            })
        }

        if (url.includes('progress')) {
            this.items.push({
                label: 'Progress',
                routerLink: this.dashboardRoutes.progress.path,
            })
        }

        if (url.includes('keywords')) {
            this.items.push({
                label: 'Search Keywords',
                routerLink: this.dashboardRoutes.keywords.path,
            })
        }

        if (url.includes('financial')) {
            this.items.push({
                label: 'Financial',
                routerLink: this.dashboardRoutes.financialV2.path,
            })
        }

        if (url.includes('users-management')) {
            this.items.push({
                label: 'Users Management',
                routerLink: this.dashboardRoutes.usersManagement.path,
            })
        }

        if (url.includes('content')) {
            this.items.push({
                label: 'Content',
                routerLink: this.dashboardRoutes.content.path,
            })
        }

        if (url.includes('team')) {
            this.items.push({
                label: 'Team',
                routerLink: this.dashboardRoutes.usersManagement.path,
            })
        }

        if (url.endsWith('queue')) {
            this.items.push({
                label: 'Queue',
                routerLink: this.dashboardRoutes.queueManagement.path,
            })
        }

        if (url.includes('profile')) {
            this.items.push({
                label: 'Profile',
                routerLink: this.dashboardRoutes.profile.path,
            })
        }
    }

    updateBreadcrumbMenuItems() {
        this.router.events.subscribe({
            next: (event: any) => {
                if (event instanceof NavigationStart) {
                    // Show progress spinner or progress bar
                    this.initBreadcrumbMenuItems(event.url)
                }
                if (event instanceof NavigationEnd) {
                    // Hide progress spinner or progress bar
                    this.currentRoute = event.url
                }
            },
        })
    }

    onItemClicked(event: any) {
        console.log('event')
        console.log(event)
    }
}
