import { inject } from '@angular/core'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { UserPermissionKeys } from '@modules/user/model/user.interface'
import { dashboardRoutes } from '@pages/dashboard/dashboard.routes'

export type SidebarMenuItem = {
    label: string
    bxIcon: string
    route: string
    permissionKey: UserPermissionKeys | ''
    shortcut?: string
}

const homeMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Home',
    bxIcon: 'bx-home',
    route: dashboardRoutes.index.path,
}

const contentMenu: SidebarMenuItem = {
    permissionKey: 'contents',
    label: 'Content',
    bxIcon: 'bx-table',
    route: dashboardRoutes.content.path,
}

const researchMenu: SidebarMenuItem = {
    permissionKey: 'research',
    label: 'Research',
    bxIcon: 'bx-hash',
    route: dashboardRoutes.research.path,
}

const cerpWriterMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Cerp Writer',
    bxIcon: 'bxs-pencil',
    route: dashboardRoutes.articleEditor.path,
}

const financialMenu: SidebarMenuItem = {
    permissionKey: 'finance',
    label: 'Financial',
    bxIcon: 'bx-wallet',
    route: dashboardRoutes.financialV2.path,
}

const paymentMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'My Payments',
    bxIcon: 'bxs-credit-card',
    route: dashboardRoutes.payment.path,
}

const progressMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Progress',
    bxIcon: 'bx-line-chart',
    route: dashboardRoutes.progress.path,
}

const queueManagementMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Queue',
    bxIcon: 'bx-add-to-queue',
    route: dashboardRoutes.queueManagement.path,
}

const queueMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Queue',
    bxIcon: 'bx-add-to-queue',
    route: dashboardRoutes.queue.path,
}

const usersAndTeamsMenu: SidebarMenuItem = {
    permissionKey: 'users',
    label: 'Users & Teams',
    bxIcon: 'bxs-user-account',
    route: dashboardRoutes.usersManagement.path,
}

const forumMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Forum',
    bxIcon: 'bxs-user-voice',
    route: dashboardRoutes.forum.path,
}

const dailyUpdatesMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Daily Updates',
    bxIcon: 'bxs-message-square-detail',
    route: dashboardRoutes.journals.path,
    shortcut: '0',
}

const chatMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Chat',
    bxIcon: 'bxs-conversation',
    route: dashboardRoutes.chat.path,
}

const socialMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Social',
    bxIcon: 'bxs-comment-dots',
    route: dashboardRoutes.social.path,
}

const newsletterMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Newsletter',
    bxIcon: 'bxs-news',
    route: dashboardRoutes.newsletter.path,
}

// const calendarMenu: SidebarMenuItem = {
//     permissionKey: '',
//     label: 'Calendar',
//     bxIcon: 'bx-calendar',
//     route: dashboardRoutes.calendar.path,
// }

export const highLevelUserMenu: SidebarMenuItem[] = [
    homeMenu,
    contentMenu,
    researchMenu,
    progressMenu,
    queueManagementMenu,
    chatMenu,
    newsletterMenu,
]

export const lowLevelUserMenu: SidebarMenuItem[] = [queueMenu, researchMenu, paymentMenu, chatMenu]

export const ownerUserMenu: SidebarMenuItem[] = [
    homeMenu,
    contentMenu,
    researchMenu,
    cerpWriterMenu,
    financialMenu,
    progressMenu,
    queueManagementMenu,
    usersAndTeamsMenu,
    forumMenu,
    dailyUpdatesMenu,
    chatMenu,
    socialMenu,
    newsletterMenu,
    // calendarMenu,
]

const canAccessMenus = (
    menus: {
        permissionKey: string
        menu: SidebarMenuItem
    }[],
) => {
    return () => {
        const authStateService = inject(AuthStateService)
        const userPermissions = authStateService.getUserPermissions()
        const userMenus: SidebarMenuItem[] = []

        for (const item of menus) {
            if (userPermissions[item.permissionKey]?.length) {
                userMenus.push(item.menu)
            }
        }

        return userMenus
    }
}

export function userPermissionMenus(userLevel: 'highLevelUser' | 'lowLevelUSer') {
    let menus: SidebarMenuItem[]
    const commonMenus = [
        {
            permissionKey: 'finance',
            menu: financialMenu,
        },
        {
            permissionKey: 'users',
            menu: usersAndTeamsMenu,
        },
        {
            permissionKey: 'social',
            menu: socialMenu,
        },
        {
            permissionKey: 'articleEditor',
            menu: cerpWriterMenu,
        },
    ]

    if (userLevel === 'highLevelUser') {
        menus = [...highLevelUserMenu, ...canAccessMenus([...commonMenus])()]
    } else {
        menus = [
            ...lowLevelUserMenu,
            ...canAccessMenus([
                ...commonMenus,
                {
                    permissionKey: 'contents',
                    menu: contentMenu,
                },
                {
                    permissionKey: 'progress',
                    menu: progressMenu,
                },
            ])(),
        ]
    }

    return menus
}
