<div class="relative flex flex-col items-center justify-center">
    <button
        (click)="toggleIcons()"
        class="btn btn-circle btn-ghost flex items-center justify-center rounded-full bg-transparent text-xl normal-case transition-colors focus:outline-none"
    >
        @if (themeService.currentTheme() === 'light') {
            <i class="bx bx-moon"></i>
        } @else if (themeService.currentTheme() === 'dark') {
            <i class="bx bx-sun"></i>
        } @else {
            <i class="bx bx-adjust"></i>
        }
    </button>

    <div
        [ngClass]="
            isRevealed()
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none -translate-y-5 opacity-0'
        "
        class="absolute right-0 top-12 transform items-center justify-center rounded-md border-[1px] border-gray-400 bg-gray-800 transition-all duration-300 ease-in-out"
    >
        <div
            class="flex cursor-pointer items-center justify-center gap-2 rounded-md px-3 py-1 text-white hover:bg-gray-700"
            (click)="onChangeTheme('dark')"
        >
            <i class="bx bx-moon text-2xl"></i> Dark
        </div>
        <div
            class="flex cursor-pointer items-center justify-center gap-2 rounded-md px-3 py-1 text-white hover:bg-gray-700"
            (click)="onChangeTheme('light')"
        >
            <i class="bx bx-sun text-2xl"></i> Light
        </div>

        <div
            class="flex cursor-pointer items-center justify-center gap-2 rounded-md px-3 py-1 text-white hover:bg-gray-700"
            (click)="onChangeTheme('auto')"
        >
            <i class="bx bx-adjust text-2xl"></i> Auto
        </div>
    </div>
</div>
