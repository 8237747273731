import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { Component, inject, signal } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ThemeService } from '@core/services/theme.service'

@Component({
    selector: 'app-theme-switcher',
    standalone: true,
    imports: [FormsModule, NgClass, NgIf, AsyncPipe],
    templateUrl: './theme-switcher.component.html',
    styleUrl: './theme-switcher.component.scss',
})
export class ThemeSwitcherComponent {
    themeService = inject(ThemeService)
    isRevealed = signal(false)

    onChangeTheme(val: 'dark' | 'light' | 'auto') {
        this.themeService.setTheme(val)
        this.toggleIcons()
    }

    toggleIcons() {
        this.isRevealed.set(!this.isRevealed())
    }
}
