import { Component, Input, input } from '@angular/core'

@Component({
    selector: 'app-navbar-logo',
    standalone: true,
    imports: [],
    templateUrl: './navbar-logo.component.html',
    styleUrls: ['./navbar-logo.component.scss'],
})
export class NavbarLogoComponent {
    isSmall = input(false)
    isDark = input(false)
}
