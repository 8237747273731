import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { TokenStorageService } from '@core/services/jwt/token-storage.service'
import { environment } from '@environment/environment'

/**
 * Adds the Bearer token to outgoing requests
 * @param request
 * @param next
 * @returns
 */
export const JwtInterceptorFn: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const accessToken = inject(TokenStorageService).getAccessToken()
    const isApiUrl = request.url.startsWith(environment.BASE_URL)
    const excludes = [
        'auth/login',
        'auth/register',
        'auth/refresh-access-token',
        'auth/forgot-password',
        'auth/reset-password',
        'auth/verify-account',
    ]
    const isWhiteListed = excludes.some((ex) => request.url.includes(ex))
    let changedRequest = request

    if (accessToken && isApiUrl && !isWhiteListed) {
        changedRequest = request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }

    return next(changedRequest)
}
