@if (!isSmall()) {
    @if (isDark()) {
        <img src="/assets/images/cerp-logo-addon-dark.png" alt />
    } @else {
        <img src="/assets/images/cerp-logo-addon-light.png" alt />
    }
} @else {
    @if (isDark()) {
        <img src="/assets/images/shrink-cerp-logo-addon-dark.png" alt />
    } @else {
        <img src="/assets/images/shrink-cerp-logo-addon-light.png" alt />
    }
}
