import { AsyncPipe, DatePipe, JsonPipe, NgClass } from '@angular/common'
import {
    AfterViewInit,
    Component,
    Injector,
    Input,
    OnDestroy,
    type OnInit,
    Renderer2,
    inject,
} from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterLink } from '@angular/router'
import { UseFallbackImageDirective } from '@core/directives/use-fallback-image.directive'
import { ThemeService } from '@core/services/theme.service'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { environment } from '@environment/environment'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatListComponent } from '@modules/chat/components/chat-list/chat-list.component'
import { ChatService } from '@modules/chat/services/chat.service'
import { DailyUpdateDialogComponent } from '@modules/journal/components/daily-update-dialog/daily-update-dialog.component'
import { JournalStateService } from '@modules/journal/states/journal-state.service'
import { NotificationStateService } from '@modules/navbar/services/notification-state.service'
import { TodoStateService } from '@modules/todo/states/todo-state.service'
import { TodoPanelComponent } from '@modules/todo/todo-panel/todo-panel.component'
import { USER_ROLES, UserRole } from '@modules/user/model/user-roles.model'
import { getAdminRoutes } from '@pages/admin/admin.routes'
import { dashboardRoutes } from '@pages/dashboard/dashboard.routes'
import { planRoutes } from '@pages/plans/plan.routes'
import { shopRoutes } from '@pages/shop/shop.routes'
import { TuiLetModule } from '@taiga-ui/cdk'
import { TuiDialogService, TuiHintModule } from '@taiga-ui/core'
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { debounceTime, map, timer } from 'rxjs'
import { ChatContentComponent } from '../../../chat/components/chat-content/chat-content.component'
import { NavbarLogoComponent } from '../navbar-logo/navbar-logo.component'
import { ThemeSwitcherComponent } from '../theme-switcher/theme-switcher.component'

@Component({
    selector: 'app-user-navbar',
    templateUrl: './user-navbar.component.html',
    styleUrls: ['./user-navbar.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        RouterLink,
        TuiLetModule,
        ReactiveFormsModule,
        TuiHintModule,
        UseFallbackImageDirective,
        DatePipe,
        JsonPipe,
        FormsModule,
        TodoPanelComponent,
        NavbarLogoComponent,
        ...TaigaUiImports,
        ChatContentComponent,
        ChatListComponent,
        ThemeSwitcherComponent,
    ],
})
export class UserNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    private hotkeysService = inject(HotkeysService)
    appStateService = inject(AppStateService)
    authStateService = inject(AuthStateService)
    notificationStateService = inject(NotificationStateService)
    todoStateService = inject(TodoStateService)
    journalStateService = inject(JournalStateService)
    chatService = inject(ChatService)
    themeService = inject(ThemeService)
    private renderer = inject(Renderer2)
    private readonly dialogs = inject(TuiDialogService)
    private readonly injector = inject(Injector)

    @Input() currentPage: string
    isDrawerOpen = false

    readonly planRoutes = planRoutes
    readonly shopRoutes = shopRoutes
    readonly adminRoutes = getAdminRoutes()
    readonly dashboardRoutes = dashboardRoutes
    readonly billingUrl = environment.STRIPE_BILLING_PORTAL_URL
    readonly extensionId = environment.EXTENSION_ID

    isExtensionInstalled = false
    notificationItems: unknown[] = []
    date$ = timer(0, 1000).pipe(map(() => new Date()))
    roles = USER_ROLES
    ownerRoleChangeControl = new FormControl(
        this.authStateService.getState().user?.role ?? UserRole.Writer,
    )

    private readonly hotkeys: Hotkey[] = [
        new Hotkey('m', (event: KeyboardEvent): boolean => {
            this.toggleSidebar()
            return false
        }),
    ]

    private setSidebarVisibilityBasedOnScreenSize() {
        const isSmallScreen = window.innerWidth < 1300
        this.appStateService.saveSidebarVisibility(!isSmallScreen)
    }

    ngOnInit(): void {
        this.onChangeUserRole()
        this.hotkeysService.add(this.hotkeys)
        this.setSidebarVisibilityBasedOnScreenSize()
        this.renderer.listen('window', 'resize', () => {
            this.setSidebarVisibilityBasedOnScreenSize()
        })
    }

    toggleDrawer() {
        this.isDrawerOpen = !this.isDrawerOpen
    }

    closeDrawer() {
        this.isDrawerOpen = false
    }

    ngAfterViewInit(): void {
        this.isExtensionInstalled = this.getIsExtensionInstalled()
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.hotkeys)
    }

    logout() {
        const { isEnabled, isCreated } = this.journalStateService.getState()
        if (isEnabled && !isCreated) {
            this.showJournalEntryDialog(true)
        } else {
            this.authStateService.logout()
        }
    }

    showJournalEntryDialog(logoutAfterSubmit: boolean) {
        const dialog = this.dialogs.open(
            new PolymorpheusComponent(DailyUpdateDialogComponent, this.injector),
            {
                dismissible: true,
                size: 'auto',
                data: { logoutAfterSubmit },
            },
        )

        dialog.subscribe({
            next: (data) => {},
            complete: () => {},
        })
    }

    toggleSidebar() {
        const sidebarOpen = this.appStateService.getState().sidebarVisible
        this.appStateService.saveSidebarVisibility(!sidebarOpen)
    }

    // toggleTheme() {
    //     this.appStateService.saveIsDarkMode(!this.appStateService.getState().isDarkMode)
    // }

    loadMoreNotifications() {
        this.notificationStateService.setState({
            page: this.notificationStateService.getState().page + 1,
        })
    }

    private getIsExtensionInstalled(): boolean {
        const res = document.getElementById(this.extensionId) !== null
        return res
    }

    private onChangeUserRole() {
        this.ownerRoleChangeControl.valueChanges.pipe(debounceTime(500)).subscribe({
            next: (value) => {
                this.authStateService.switchToUserMode(value)
            },
        })
    }
}
