<div class="navbar bg-base-200">
    <div class="flex-1">
        <a class="btn btn-ghost text-xl normal-case" (click)="toggleSidebar()">
            <i class="bx bx-menu text-2xl text-base-content"></i>
        </a>
    </div>
    <div class="flex-none">
        <ul class="menu menu-horizontal hidden px-1 md:flex">
            <li>
                <span class="pt-2 opacity-60">
                    {{ date$ | async | date: 'EEEE, MMMM d, y h:mm a' }}
                </span>
            </li>
        </ul>

        <div class="dropdown dropdown-end">
            <app-theme-switcher></app-theme-switcher>
        </div>

        <div class="dropdown dropdown-end ml-8">
            <label tabindex="0" class="avatar btn btn-circle btn-ghost">
                <div class="w-10 rounded-full">
                    <img
                        useFallbackImage
                        [src]="(authStateService.select('user') | async).profileImage"
                    />
                </div>
            </label>
            <ul
                tabindex="0"
                class="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
            >
                <li>
                    <a [routerLink]="dashboardRoutes.index.path">User Dashboard</a>
                </li>
                <li>
                    <a (click)="logout()">Logout</a>
                </li>
            </ul>
        </div>
    </div>
</div>
