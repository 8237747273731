import { Injectable, inject } from '@angular/core'
import { SimpleStore } from '@core/states/simple-store'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { UserLog } from '@modules/user-log/models/user-log.model'
import { UserLogApiService } from '@modules/user-log/services/user-log-api.service'
import { combineLatest, debounceTime, interval, switchMap, tap } from 'rxjs'

export interface NotificationListState {
    page: number
    size: number
    loading: boolean
    userLogs: UserLog[]
}

const initialNotificationListState: NotificationListState = {
    page: 1,
    size: 20,
    loading: false,
    userLogs: [],
}

const NOTIFICATION_FETCH_INTERVAL = 20 * 60 * 1000

@Injectable({
    providedIn: 'root',
})
export class NotificationStateService extends SimpleStore<NotificationListState> {
    private authStateService = inject(AuthStateService)
    private userLogApiService = inject(UserLogApiService)

    constructor() {
        super(initialNotificationListState)
    }

    init() {
        this.continueLoadingNotifications()
        this.continueLoadingNotificationsAtInterval()
    }

    private continueLoadingNotifications() {
        combineLatest([this.select('page'), this.select('size')])
            .pipe(
                debounceTime(300),
                tap(() => this.setState({ loading: true })),
                switchMap(([currentpage, pageSize]) => {
                    return this.userLogApiService.find({
                        page: currentpage,
                        size: pageSize,
                        userId: this.authStateService.getUserId(),
                        orderBy: 'asc',
                        sortBy: 'createdAt',
                    })
                }),
            )
            .subscribe((res) => {
                const { page, userLogs } = this.getState()
                this.setState({
                    // if page = 1, then replace the data, otherwise append the data
                    userLogs: page === 1 ? res.data : [...userLogs, ...res.data],
                    loading: false,
                })
            })
    }

    private continueLoadingNotificationsAtInterval() {
        interval(NOTIFICATION_FETCH_INTERVAL).subscribe(() => {
            this.setState({ page: 1 })
        })
    }
}
