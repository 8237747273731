import { AsyncPipe } from '@angular/common'
import { Component, Input, inject } from '@angular/core'
import { RouterModule, RouterOutlet } from '@angular/router'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { FeedbackApiService } from '@modules/feedback/services/feedback-api.service'
import { UserNavbarComponent } from '@modules/navbar/components/user-navbar/user-navbar.component'
import { PlanRoutes, planRoutes } from '@pages/plans/plan.routes'

@Component({
    standalone: true,
    selector: 'app-layout-plans',
    templateUrl: './layout-plans.component.html',
    styleUrls: ['./layout-plans.component.scss'],
    imports: [...TaigaUiImports, AsyncPipe, RouterModule, RouterOutlet, UserNavbarComponent],
})
export class LayoutPlansComponent {
    appStateService = inject(AppStateService)
    feedbackService = inject(FeedbackApiService)

    items: { label: string; routerLink: string; icon?: string }[] = []
    currentRoute = ''
    proPlan = false

    readonly planRoutes: PlanRoutes = planRoutes
}
